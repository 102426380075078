<template>
  <div class="card card-custom gutter-b">
    <div class="card-header pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Simulation </span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <b-row class="mb-5">
        <b-col cols="3">
          <h5>Kunde</h5>

          <b-input-group size="sm" label="Kundennummer">
            <b-form-input type="number" v-model="kundenNummer"></b-form-input>

            <template #append>
              <b-button @click="getKundenData" variant="success">Laden</b-button>
            </template>
          </b-input-group>
          <b-alert class="mt-2" :show="errorMsg ? true : false" variant="danger">{{ errorMsg }}</b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form class="text-left">
            <b-row>
              <b-col cols="2">
                <b-form-group label="Anrede" label-for="anrede">
                  <b-form-input
                    :disabled="isLoading"
                    id="anrede"
                    v-model="kunde.anrede"
                    placeholder="Anrede"
                    type="text"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Titel" label-for="titel">
                  <b-form-input
                    :disabled="isLoading"
                    id="titel"
                    v-model="kunde.titel"
                    placeholder="Titel"
                    type="text"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Vorname" label-for="vorname">
                  <b-form-input
                    :disabled="isLoading"
                    id="vorname"
                    v-model="kunde.vorname"
                    placeholder="Vorname"
                    type="text"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Nachname" label-for="nachname">
                  <b-form-input
                    :disabled="isLoading"
                    id="nachname"
                    v-model="kunde.nachname"
                    placeholder="Nachname"
                    type="text"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="E-Mail" label-for="email">
              <b-form-input
                :disabled="isLoading"
                id="email"
                v-model="kunde.email"
                placeholder="E-Mail"
                type="email"
                required
              />
            </b-form-group>
            <b-form-group label="Adresse">
              <b-form-input
                :disabled="isLoading"
                class="mb-2"
                name="Straße und Hausnummer"
                placeholder="Straße und Hausnummer"
                v-model="kunde.strasse"
              />
              <b-row>
                <b-col cols="4"
                  ><b-form-input
                    :disabled="isLoading"
                    v-model="kunde.postleitzahl"
                    name="Postleitzahl"
                    placeholder="Postleitzahl"
                    type="number"
                  ></b-form-input
                ></b-col>
                <b-col cols="8"
                  ><b-form-input
                    :disabled="isLoading"
                    v-model="kunde.ort"
                    name="Ort"
                    placeholder="Ort"
                  ></b-form-input
                ></b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Land" label-for="land">
              <b-form-input
                :disabled="isLoading"
                id="land"
                v-model="kunde.land"
                placeholder="Land"
                type="text"
              />
            </b-form-group>
            <b-form-group label="Telefon Mobil" label-for="telefonMobil">
              <b-form-input
                :disabled="isLoading"
                id="telefonMobil"
                v-model="kunde.telefonMobil"
                placeholder="Telefon Mobil"
                type="text"
              />
            </b-form-group>
            <b-form-group label="Telefon Privat" label-for="telefonPrivat">
              <b-form-input
                :disabled="isLoading"
                id="telefonPrivat"
                v-model="kunde.telefonPrivat"
                placeholder="Telefon Privat"
                type="text"
              />
            </b-form-group>
            <b-form-group label="Telefon Geschäftlich" label-for="telefonGesch">
              <b-form-input
                :disabled="isLoading"
                id="telefonGesch"
                v-model="kunde.telefonGeschaeftlich"
                placeholder="Telefon Geschäftlich"
                type="text"
              />
            </b-form-group>

            <b-button @click="onSubmit" variant="primary" class="float-right">Absenden</b-button>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { mapState } from 'vuex';
import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';

export default {
  name: 'einstellungen-test-data',
  data() {
    return {
      kundenNummer: null,
      isLoading: false,
      errorMsg: null,
      kunde: {
        id: '',
        anrede: '',
        titel: '',
        vorname: '',
        nachname: '',
        land: '',
        strasse: '',
        postleitzahl: '',
        ort: '',
        email: '',
        telefonPrivat: '',
        telefonGeschaeftlich: '',
        telefonMobil: '',
        loaded: false,
      },
    };
  },
  methods: {
    async getKundenData() {
      if (this.kundenNummer) {
        const kunde = await this.getKundeById(this.kundenNummer);
        if (kunde) {
          this.kunde.id = kunde.id;

          this.kunde.anrede = kunde.name.anrede;
          this.kunde.titel = kunde.name.titel ? kunde.name.titel.value : '';
          this.kunde.vorname = kunde.name.vorname ? kunde.name.vorname.value : '';
          this.kunde.nachname = kunde.name.nachname ? kunde.name.nachname.value : '';

          this.kunde.land = kunde.adresse.land ? kunde.adresse.land.value : '';
          this.kunde.strasse = kunde.adresse.strasse ? kunde.adresse.strasse.value : '';
          this.kunde.postleitzahl = kunde.adresse.postleitzahl ? kunde.adresse.postleitzahl.value : '';
          this.kunde.ort = kunde.adresse.ort ? kunde.adresse.ort.value : '';

          this.kunde.email = kunde.email ? kunde.email.value : '';
          this.kunde.telefonPrivat = kunde.telefonPrivat ? kunde.telefonPrivat.value : '';
          this.kunde.telefonGeschaeftlich = kunde.telefonGeschaeftlich
            ? kunde.telefonGeschaeftlich.value
            : '';
          this.kunde.telefonMobil = kunde.telefonMobil ? kunde.telefonMobil.value : '';
          this.kunde.loaded = true;
        }
      }
    },
    async getKundeById(id) {
      this.isLoading = true;
      const filter = { id: parseInt(id) };
      return odataService
        .getKunde({
          filter,
          expand: {
            Name: {},
            Adresse: { expand: { Strasse: {}, Postleitzahl: {}, Ort: {}, Land: {} } },
            TelefonPrivat: {},
            TelefonGeschaeftlich: {},
            TelefonMobil: {},
          },
          count: true,
        })
        .then(response => {
          this.errorMsg = response.count === 0 ? `Kunden mit Kundennummer ${id} nicht gefunden!` : null;
          return response.data[0];
        })
        .catch(error => {
          this.errorMsg = error;
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSubmit() {
      this.isReiseterminAbsagenLoading = true;
      let submittedKunde = { ...this.kunde };
      // Replaces all empty string values with null
      Object.keys(submittedKunde).map(
        key => (submittedKunde[key] = submittedKunde[key] === '' ? null : submittedKunde[key])
      );

      apiService
        .put(`Kunde/${this.kunde.id}/simulate/blank`, {
          id: submittedKunde.id,
          titel: submittedKunde.titel,
          vorname: submittedKunde.vorname,
          nachname: submittedKunde.nachname,
          email: submittedKunde.email,
          strasse: submittedKunde.strasse,
          postleitzahl: submittedKunde.postleitzahl,
          ort: submittedKunde.ort,
          land: submittedKunde.land,
          telefonPrivat: submittedKunde.telefonPrivat,
          telefonGeschaeftlich: submittedKunde.telefonGeschaeftlich,
          telefonMobil: submittedKunde.telefonMobil,
        })
        .then(() => {
          this.errorMsg = null;
          this.toast(`Kunde ${submittedKunde.id} gespeichert!`);
        })
        .catch(error => {
          this.errorMsg = error;
          this.toast('Fehler beim Speichern!', 'danger');
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapState({
      roles: state => state.auth.account.idTokenClaims.roles,
    }),
  },
};
</script>
